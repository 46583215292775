<template>
    <main class="main-wrapper">
        <!-- Start Checkout Area  -->
        <div class="axil-checkout-area axil-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="axil-checkout-billing">
                            <h4 class="title mb--40">
                                {{ $t("Sikeres megrendelés") }}
                            </h4>
                            <p class="my-4">
                                {{
                                    $t(
                                        "Rendelésedet fogadtuk! E-mailben elküldtük a rendelés részleteit. A teljesítetlen rendeléseket 1 hét után töröljük."
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: "SuccessOrder"
};
</script>

<style scoped></style>
